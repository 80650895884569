<template>
    <div id="login">
        <input type="text" name="username" v-model="input.username" placeholder="Username" />
        <input type="password" name="password" v-model="input.password" placeholder="Password" />
        <button type="button" v-on:click="login()">Login</button>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                input: {
                    username: "",
                    password: ""
                }
            }
        },
        methods: {
            login() {
                if(this.input.username != "" && this.input.password != "") {
                    if(this.input.username == this.$parent.mockAccount.username && this.input.password == this.$parent.mockAccount.password) {
                        this.$emit("authenticated", true);
                        this.$router.replace({ name: "Statistics" });
                    } else {
                        console.log("The username and / or password is incorrect");
                    }
                } else {
                    console.log("A username and password must be present");
                }
            }
        }
    }
</script>

<style scoped>
#login {
    position: relative;
    width: 215px;

    left: 30%; 
    height: auto;
    margin-top: 10%;
/*    margin-left: -20%; */
    background: rgba(255,255,255,0.5);
    border-radius: 30px;
    border: 1px solid #ccc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    padding: 20px;
	
}

#login input {

margin: inherit;

}

#login button {
    display: flex;
    /* width: 100%; */
    /* text-align: center; */
    margin: auto;
    margin-top: 3vh;
    padding-left: 4vh;
    padding-right: 4vh;
    border-radius: 8px;
    padding-top: 3px;
    padding-bottom: 4px;
}

    #app {

  > * {
    height: 100hv;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
  }
    }

</style>
